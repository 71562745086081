// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { CloudRunPathBuilder, Env, ICandidateEnvironment } from 'shared-lib'

const CANDIDATE_BASE_URL = 'https://dev-network.cnected.com'

const env: Env = 'dev'
//const pathBuilder = new AppEnginePathBuilder(env)
const pathBuilder = new CloudRunPathBuilder('dr32hxly6a')

export const environment: ICandidateEnvironment = {
  production: false,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyCnP-U4Q9cCTJ6jCB5aAfdNdPm66Owq6_4',
    authDomain: 'cnect-dev.firebaseapp.com',
    databaseURL: 'https://cnect-dev.firebaseio.com',
    projectId: 'cnect-dev',
    storageBucket: 'cnect-dev.appspot.com',
    messagingSenderId: '114692117909',
    appId: '1:114692117909:web:3f87fbdbc0fee67c74129c',
    measurementId: 'G-3M21SWBXKR',
  },
  services: {
    cloudFunctions: 'https://us-central1-cnect-dev.cloudfunctions.net',
    jobs: pathBuilder.build('jobs'), // 'https://jobs-rest-dot-cnect-dev.uc.r.appspot.com/jobs-api-gateway',
    jobsAutomation: pathBuilder.build('jobsAutomation'), // 'https://jobs-automation-rest-cnect-dev.uc.r.appspot.com/jobs-automation-api-gateway
    candidate: pathBuilder.build('candidate'), // 'https://candidate-rest-dot-cnect-dev.uc.r.appspot.com/candidate-api-gateway',
    events: pathBuilder.build('events'), // 'https://events-rest-dot-cnect-dev.uc.r.appspot.com/events-api-gateway',
    registration: pathBuilder.build('registration'), // 'https://registration-rest-dot-cnect-dev.uc.r.appspot.com/registration-api-gateway',
    applicationHistory: pathBuilder.build('applicationHistory'), // 'https://application-history-rest-dot-cnect-dev.uc.r.appspot.com/application-history-api-gateway',
    content: pathBuilder.build('content'), // 'https://content-rest-dot-cnect-dev.uc.r.appspot.com/content-api-gateway',
    employer: pathBuilder.build('employer'), // 'https://employer-rest-dot-cnect-dev.uc.r.appspot.com/employer-api-gateway/',
    auth: pathBuilder.build('auth'), // 'https://auth-rest-dot-cnect-dev.uc.r.appspot.com/auth-api-gateway/',
    files: pathBuilder.build('files'), // 'https://files-rest-dot-cnect-dev.uc.r.appspot.com/files-api-gateway/',
    messaging: pathBuilder.build('messaging'), // 'https://messaging-rest-dot-cnect-dev.uc.r.appspot.com/messaging-api-gateway/',
    community: pathBuilder.build('community'), // 'https://community-rest-dot-cnect-dev.uc.r.appspot.com/community-api-gateway/',
    virtualDialogue: pathBuilder.build('virtualDialogue'), // 'https://virtual-dialogue-rest-dot-cnect-dev.uc.r.appspot.com/virtual-dialogue-api-gateway/',
    access: pathBuilder.build('access'), // 'https://access-rest-dot-cnect-dev.uc.r.appspot.com/access-api-gateway/',
    social: pathBuilder.build('social'), // 'https://social-rest-dot-cnect-dev.uc.r.appspot.com/social-api-gateway',
    notifications: pathBuilder.build('notifications'), // 'https://notifications-rest-dot-cnect-dev.uc.r.appspot.com/notifications-api-gateway/',
    user: pathBuilder.build('user'),
    sse: pathBuilder.build('sse', false),
    product: pathBuilder.build('product'),
  },
  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: false, // Look to the auth guard for use of this value
  themesEnabled: true,
  v2ReCaptcha: '6LcVas0aAAAAAAcHaLi9835I5X1EPovMGnsTVm_Z',
  tenantId: 'B2C-USER-bafvb',
  cdnCname: 'dev-content.cnected.com',
  basePlatformHost: 'dev-network.cnected.com',
  algoliaAppId: 'SH1DAV8W6D',
  version: '02dfe44',
  employerEmailActionRoute: 'https://dev-network.cnected.com/#/auth/actions',
  candidateEmailSuccessRoute: 'https://dev-network.cnected.com/#/auth/action-success/verifyEmail',
  passwordRecoveryEmailTemplateId: 'GQKsG5Wnv9sWJ9uY3aDf',
  emailVerificationEmailTemplateId: 'cwdpiIuZAP8dPQqB5s5K',
  betaMessageId: 'FoyYlvOzSxbxbMvU2ykE',
  candidateBaseUrl: CANDIDATE_BASE_URL,
  appDownloadDynamicLink: 'https://cnect.page.link/uEsh',
  tenant: {
    appConfigBaseURL: 'https://dev-content.cnected.com/assets/bootstrap',
    defaultTenant: 'dev-network.cnected.com',
  },
  smartBannerOptions: {
    appIcon: 'https://play-lh.googleusercontent.com/W170UOarQtwNy4GY1JXosU6SD3LBY5OzTYOWsRUL9W6yXYwPoCeUVGeTtuO850ByjSw=w480-h960-rw',
    appStoreUrl: 'https://apps.apple.com/us/app/cnected/id6467040985',
    playStoreUrl: 'https://play.google.com/store/apps/details?id=com.e11.prod.cnect',
    ttl: `${24 * 3.6e6}`,
  },
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
