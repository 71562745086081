<div *ngIf="jobDetail$ | async as jobDescription">
  <header class="" style="background-color: {{ employerTheme?.primary }}">
    <nav class="e11-max-w-7xl e11-mx-auto e11-px-4 sme11-:px-6 lg:e11-px-8" aria-label="Top">
      <div class="e11-w-full e11-py-6 e11-flex e11-items-center e11-justify-between lg:e11-border-none">
        <div class="e11-w-full e11-flex e11-items-center">
          <div>
            <img
              class="e11-float-left e11-h-10 e11-w-auto e11-mr-4"
              *ngIf="jobDescription.employerLogo"
              [src]="jobDescription.employerLogo"
              [alt]="jobDescription.employerName"
            />
          </div>
          <div class="e11-grow">
            <h3 class="" style="color: {{ employerTheme?.text }}">{{ jobDescription.employerName }}</h3>
            <h4 style="color: {{ employerTheme?.text }}">{{ jobDescription.jobTitle }}</h4>
          </div>
        </div>
      </div>
    </nav>
  </header>

  <div class="e11-relative">
    <div class="e11-max-w-full e11-mx-auto">
      <div class="e11-relative e11-shadow-xl sm:e11-overflow-hidden">
        <div class="e11-absolute e11-inset-0">
          <img class="e11-h-full e11-w-full e11-object-cover" src="{{ employerHeroImage }}" alt="" />
          <div style="background-color: {{ employerTheme?.primary }}" class="e11-absolute e11-inset-0 e11-mix-blend-multiply"></div>
        </div>

        <div class="e11-max-w-7xl e11-mx-auto e11-relative e11-px-4 md:e11-py-16 sm:e11-px-6 e11-pt-4 e11-pb-12 lg:e11-py-16 lg:e11-px-8">
          <e11-panel>
            <div class="e11-justify-items-center e11-p-8" *ngIf="jobDescription?.jobPaused">
              <h3 class="e11-p-8 e11-font-bold e11-text-center">UNFORTUNATELY THIS JOB POST IS CURRENTLY UNAVAILABLE</h3>
              <p class="e11-mt-4 e11-text-lg e11-text-gray-500">Please click on the logo to return to careers</p>
            </div>
            <div *ngIf="!jobDescription?.jobPaused">
              <div *ngIf="jobDetail$ | async as jobDescription">
                <div class="e11-flex e11-items-start e11-justify-center e11-mb-4">
                  <h2 class="e11-justify-center">{{ 'Choose how you would like to apply!' | translate }}</h2>
                </div>

                <div class="e11-relative e11-overflow-x-hidden e11-overflow-y-auto e11-h-screen">
                  <!-- Bento -->
                  <div
                    [ngClass]="{ 'out-to-left': showRegistrationForm, 'in-from-left': !showRegistrationForm }"
                    class="register e11-bg-skin-grey/20 e11-rounded-md is-active e11-absolute e11-w-full e11-px-6 e11-pt-6 e11-pb-8"
                  >
                    <div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-2 e11-gap-6">
                      <div>
                        <e11-panel [containerClassOverrides]="'e11-flex e11-h-full e11-mb-0'">
                          <h3 class="e11-text-skin-primary-accent e11-mb-1">
                            {{ 'Upload your resume' | translate }}
                          </h3>
                          <p>{{ 'Quickly and automatically build an application by uploading your resume.' | translate }}</p>

                          <!-- File Drop - Uploader -->
                          <div>
                            <sdk-file-drop
                              *ngIf="!resumeFile"
                              (newFile)="resumeFile = $event"
                              [size]="maxFileSize"
                              [allowedFileTypes]="resumeFileTypes"
                              [dropInstruction]="'Drag and drop your resume here' | translate"
                            ></sdk-file-drop>
                            <!-- Successfully uploaded resume -->
                            <div *ngIf="resumeFile" class="e11-pt-8">
                              <h4 *ngIf="resumeFile" class="e11-font-bold e11-font-skin-poppins-bold e11-mb-4">
                                {{ 'Great! We will use your resume.' | translate }}
                              </h4>
                              <div
                                class="e11-w-full e11-border e11-border-skin-success e11-bg-skin-success/10 e11-p-4 e11-rounded e11-mb-8"
                                *ngIf="resumeFile"
                              >
                                <div class="e11-flex e11-items-center e11-justify-center e11-text-sm">
                                  <div>
                                    <span class="material-icons-outlined md-36 e11-text-skin-success"> check </span>
                                  </div>
                                  <div class="e11-grow e11-text-center">
                                    <span class="e11-flex e11-items-center e11-line-clamp-1">
                                      {{ resumeFile.name }}
                                    </span>
                                  </div>
                                  <div>
                                    <span
                                      class="material-icons-outlined e11-ml-2 e11-cursor-pointer e11-text-skin-light hover:e11-text-skin-dark"
                                      (click)="clearSelectedFile()"
                                    >
                                      cancel
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="e11-text-center">
                                <e11-button
                                  *ngIf="resumeFile"
                                  (click)="switchShowRegistrationForm(true)"
                                  [size]="'md'"
                                  [color]="'primary-accent'"
                                  value="{{ 'Next' | translate }}"
                                  [icon]="'east'"
                                  [iconPosition]="'right'"
                                  [iconSize]="'md-18'"
                                >
                                </e11-button>
                              </div>
                            </div>
                          </div>
                        </e11-panel>
                      </div>

                      <div class="e11-flex e11-flex-col">
                        <div class="e11-grow e11-pb-6">
                          <e11-panel [containerClassOverrides]="'e11-flex e11-h-full'">
                            <h3 class="e11-text-skin-primary-accent e11-mb-1">
                              {{ "Don't have a resume?" | translate }}
                            </h3>
                            <p class="e11-mb-4">
                              {{
                                'No problem! You can still continue working your way through your profile update process without one! Fill out a blank application to use for this job.'
                                  | translate
                              }}
                            </p>
                            <div class="e11-flex e11-flex-col e11-items-center e11-justify-center">
                              <e11-button
                                *ngIf="!showRegistrationForm"
                                (click)="switchShowRegistrationForm(true)"
                                [size]="'md'"
                                [color]="'primary-accent'"
                                value="{{ 'Continue without resume' | translate }}"
                                [icon]="'east'"
                                [iconPosition]="'right'"
                                [iconSize]="'md-18'"
                              >
                              </e11-button>
                            </div>
                          </e11-panel>
                        </div>
                        <div class="">
                          <e11-panel [containerClassOverrides]="'e11-flex e11-h-full e11-mb-0'">
                            <h3 class="e11-text-skin-primary-accent e11-mb-1">
                              {{ 'Sign into Cnect!' | translate }}
                            </h3>
                            <p>
                              {{ 'Already have a cnect account? Sign in to jump right into an application for this job. You can also ' | translate }}
                              <span
                                (click)="register()"
                                class="e11-font-bold e11-text-skin-secondary hover:e11-border-b hover:e11-border-skin-secondary hover:e11-cursor-pointer"
                                >{{ 'Register here!' }}</span
                              >
                            </p>
                            <div class="e11-flex e11-flex-col e11-items-center e11-justify-center">
                              <e11-button
                                [size]="'md'"
                                [ghost]="true"
                                [value]="'Sign into Cnect' | translate"
                                [color]="'secondary'"
                                (click)="navigateToLogin()"
                              >
                              </e11-button>
                            </div>
                          </e11-panel>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Register Form-->
                  <div
                    [ngClass]="{ 'in-from-right': showRegistrationForm, 'out-to-right': !showRegistrationForm }"
                    class="register e11-bg-skin-grey/20 e11-rounded-md e11-absolute e11-w-full e11-p-6"
                  >
                    <e11-panel>
                      <e11-button
                        [value]="'Back'"
                        (click)="back()"
                        [color]="'primary-accent'"
                        [ghost]="true"
                        [size]="'sm'"
                        [icon]="'west'"
                        [iconPosition]="'left'"
                        [iconSize]="'sm'"
                      >
                      </e11-button>
                      <div
                        class="e11-flex e11-flex-col e11-justify-center e11-items-center"
                        [ngClass]="{ 'out-to-right': !showRegistrationForm, 'in-from-right': showRegistrationForm }"
                      >
                        <h3 class="e11-text-center e11-mb-4">{{ 'Just a few more pieces of information...' | translate }}</h3>
                        <sdk-registration-consumer
                          submitButtonText="{{ 'Continue' | translate }}"
                          (onSubmit)="onSubmit($event)"
                          (termsClicked)="termsClicked()"
                        ></sdk-registration-consumer>
                        <e11-loader-dots
                          *ngIf="uploadingResume"
                          [size]="'md'"
                          [fullScreen]="true"
                          [color]="'e11-bg-skin-primary-accent'"
                          [hasBackdrop]="true"
                          [backdropStyle]="'dark'"
                          [fontColor]="'e11-text-white'"
                          label="{{ 'Finalizing application... please wait a moment.' | translate }}"
                        ></e11-loader-dots>
                      </div>
                    </e11-panel>
                  </div>
                </div>
              </div>
            </div>
          </e11-panel>
        </div>
      </div>
    </div>
  </div>
</div>
